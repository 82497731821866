import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function BobbysPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-name-filter="bobby" data-fixr-shop-id="be31447a-9d7b-4108-bde6-c9cb9a5287e1" data-fixr-theme="light"></script>`}
      imageSrc="/logo/bobbys-logo.png"
      backgroundSrc="/hero/bobbys-hero.jpg"
      imageMaxWidth="322px"
      title="BOBBY'S DISCO CLUB"
      description={
        <>
          <p>
            Disco Bobby invites you to his fabulous and sensationally popular
            Disco Club every Monday at The Warehouse. A magical night of all his
            favourite Disco tunes & big confetti blasts showering down onto a
            super friendly dancefloor full of beautiful people and smiling
            faces. A truly welcoming and feel good experience, entering its 6th
            year of spreading Disco goodness across Leeds. Be sure to keep an
            eye out for his special one off Disco Festivals, Disco Shows and
            Halloween Deadly Disco popping up at all the best venues in the
            city.
          </p>
          <p> Fabulous. Disco. Forever. </p>
        </>
      }
    />
  );
}
